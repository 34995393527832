
















































  import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
  import { BInputGroup } from 'bootstrap-vue/src/components/input-group/'

  @Component({
    components: {
      BInputGroup,
    },
  })
  export default class OneSearchBar extends Vue {
    name: string = 'one-search-bar'

    @Prop({
      type: Array,
    })
    categories?: Array<any>

    @Prop({
      type: String,
    })
    placeholder?: string

    @Prop({
      type: Boolean,
      default: false,
    })
    searchOnTyping!: boolean

    @Prop({
      type: Boolean,
      default: true,
    })
    isDropdown!: boolean

    @Prop({
      type: Boolean,
      default: false,
    })
    clearable!: boolean

    @Prop({
      type: String,
      required: true,
    })
    searchText!: string

    @Prop({
      type: String,
      default: 'Search',
    })
    searchTextSr!: string

    @Prop({
      type: String,
    })
    selectedCategory: string | undefined

    @Prop({
      type: Boolean,
      default: false,
    })
    autoFocus!: boolean

    @Prop({
      type: String,
      required: false,
    })
    inputTestId?: string

    @Prop({
      type: String,
      required: false,
    })
    searchButtonTestId?: string

    @Prop({
      type: String,
      required: false,
    })
    dropdownButtonTestId?: string

    @Prop({
      type: String,
      required: false,
    })
    dropdownItemTestId?: string

    typingTimeout: any
    searchTimeout: any

    searchInput: string = ''
    debounceTimeout: number = 250

    @Watch('searchText', { immediate: true })
    onSearchTextUpdate() {
      this.searchInput = this.searchText
    }

    onInputUpdate(query: string): void {
      clearTimeout(this.typingTimeout)
      if (query.trim()) {
        this.typingTimeout = setTimeout(() => {
          this.$emit('search-update', query)
        }, this.debounceTimeout)
      } else {
        this.$emit('search-update', query)
      }
    }

    onSearch(): void {
      if (this.searchInput.trim() !== '') {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(() => {
          this.$emit('search', this.searchInput.trim())
        }, this.debounceTimeout)
      }
    }

    destroyed() {
      clearTimeout(this.searchTimeout)
      clearTimeout(this.typingTimeout)
    }

    onClearClick() {
      this.$emit('clear')
    }

    focusSearchInput() {
      const searchInput = this.$refs.searchInput as any
      if (typeof searchInput.focus === 'function') {
        searchInput.focus()
      }
    }

    getDropdownItemTestId(index: string): string | null {
      return this.dropdownItemTestId ? `${this.dropdownItemTestId}-${index}` : null
    }

    get searchCategories(): Array<string> {
      return this.categories || ['']
    }

    get inputPlaceholder(): string {
      return this.placeholder || 'Czego szukasz...?'
    }

    get shouldAutofocus() {
      if (typeof window !== 'undefined') {
        return this.autoFocus || ['lg', 'xl'].includes(this.$mq)
      }
      return false
    }
  }
